<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card flat min-height="250" class="agentPrimary lighten-1">
            <v-card-text>
              <div class="white--text">
                <p class="text-h5 text-sm-h3">Your <span class="font-weight-bold">Payments</span> in our hands</p>
                <p class="my-0 text-body-1 text-sm-h6 font-weight-light">Secure payments</p>
                <p class="text-body-1 text-sm-h6 font-weight-light">With Barclaycard`s ePDQ e-Commerce payment service.</p>
                <v-btn 
                  color="agentSecondary" 
                  class="px-0" 
                  text
                  href="https://www.barclaycard.co.uk/business/help/accepting-payments/what-is-epdq"
                  target="_blank"
                >Read more...</v-btn>
              </div>
              <div class="image-container">
                <div class="log-image">
                  <v-img
                    alt="AgentOS logo"
                    contain
                    src="../assets/AgentOS-Logo.png"
                    width="100%"
                  />
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12" sm="6" >
              <v-card flat height="144">
                <v-card-text class="py-5 text-center d-flex justify-space-between align-center">
                  <div class="div-circle">
                    <span class="text-h3 primary--text">1</span>
                  </div>
                  <div class="pr-3">
                    <p class="text-h4 my-0">Payment due</p>
                    <p class="text-body-1 my-0">to Letmc Lettings</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" >
              <v-card flat>
                <v-card-text class="text-center">
                  <p class="my-0 text-body-2">Your balance</p>
                  <p class="mt-2 mb-n2 text-h4 red--text font-weight-bold">-£350.00</p>
                </v-card-text>
                <v-card-actions>
                  <v-btn 
                    depressed 
                    color="primary"
                    outlined
                    width="48%"
                  >View details</v-btn>
                  <v-btn
                    @click="paymentsDialog = true" 
                    depressed 
                    color="primary"
                    width="48%"
                  >
                    <span class="secondary--text">Pay now</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card flat>
                <v-card-title primary-title>
                  <span class="grey--text font-weight-light">Previous transactions</span>
                  <v-spacer></v-spacer>
                  <v-icon color="grey">mdi-swap-vertical</v-icon>
                </v-card-title>
                <v-card-text class="text-center">
                  <v-data-table
                    :headers="headers"
                    :items="payments"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:item.status="{item}">
                      <span
                        :class="item.status === 'Due' ? 'red--text' : 'green--text'"
                        class="d-flex align-end"
                      >{{ item.status }}
                        <v-icon v-if="item.status === 'Due'" right color="red">mdi-alert-circle-outline</v-icon>
                        <v-icon v-else right color="green">mdi-check</v-icon>
                      
                      </span>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-card flat height="144">
            <v-card-text class="text-center">
              <p class="my-0 text-h5">Make a payment</p>
              <p class="my-0 text-body-1">Make an open payment now!</p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="openPaymentsDialog = true"
                depressed 
                outlined
                color="primary"
                block
              >New payment</v-btn>
            </v-card-actions>
          </v-card>
          <v-hover v-slot="{ hover }">
            <div class="d-none d-md-block mt-6 payment-img-wrapper">
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="pa-5 transition-fast-in-fast-out agentSecondary v-card--reveal white--text"
                  style="height: 100%;"
                >
                  <p class="text-h5 mt-3 mb-5 font-weight-bold">AgentSign Payments</p>
                  <p>AgentSign helps you take care of your payments with a clean and easy solution.</p>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio officia qtempore sequi porro omnis veniam, itaque laborum optio. Earum, alias delectus?</p>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio officia quod alias delectus?</p>
                </div>
              </v-expand-transition>
            </div>
    
          </v-hover>
        </v-col>
      </v-row>

      <v-dialog
        v-model="paymentsDialog"
        scrollable  
        persistent 
        transition="dialog-transition"
        max-width="700"
      >
        <div>
          <PaymentsForm
            @closeDialog="paymentsDialog = false"
          />
        </div>
      </v-dialog>

      <v-dialog
        v-model="openPaymentsDialog"
        scrollable  
        persistent 
        transition="dialog-transition"
        max-width="700"
      >
        <div>
          <OpenPaymentsForm
            @closeDialog="openPaymentsDialog = false"
          />
        </div>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import PaymentsForm from '@/components/payments/PaymentsForm.vue';
import OpenPaymentsForm from '@/components/payments/OpenPaymentsForm.vue';

export default {
  name: 'payments-view',
  components: {
    PaymentsForm,
    OpenPaymentsForm
  },
  data() {
    return {
      paymentsDialog: false,
      openPaymentsDialog: false,
      headers: [
        {
          text: 'Property',
          align: 'start',
          sortable: false,
          value: 'property',
        },
        { text: 'Amount', value: 'amount' },
        { text: 'Date due', value: 'date' },
        { text: 'Ref.', value: 'reference' },
        { text: 'Status', value: 'status' },
      ],
      payments: [
        {
          property: '10 Downing Street',
          amount: '£350',
          date: '11/05/2021',
          reference: 'egc122',
          status: 'Due'
        },
        {
          property: '10 Downing Street',
          amount: '£350',
          date: '11/04/2021',
          reference: 'fffg36',
          status: 'Confirmed'
        },          
        {
          property: '10 Downing Street',
          amount: '£350',
          date: '11/03/2021',
          reference: 'k5lfg9sd',
          status: 'Confirmed'
        },          
        {
          property: '10 Downing Street',
          amount: '£350',
          date: '11/02/2021',
          reference: 'xx1342',
          status: 'Confirmed'
        },
      ]
    }
  },
}
</script>

<style scoped>
  .div-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid #59ddb7;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-container {
    position: relative;
    max-width: 300px;
    position: absolute;
    bottom: -20px;
    right: -20px;
    /* opacity: 0.35; */
  }

  .right-col-image {
    opacity: 0.8;
  }

  .payment-img-wrapper {
    height: 380px;
    width: 100%;
    background-image: url("../assets/payments.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.75;
    position: relative;
    border-radius: 1px;
  }

  .v-card--reveal {
    align-items: center;
    bottom: 0;
    right: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
  }

</style>