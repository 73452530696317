<template>
  <div>
    <v-card flat>
      <v-card-title class="agentPrimary white--text py-2">
        Payment Checkout
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" color="white" fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-8 pb-0">
        <v-row v-if="!formConfirmed">
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="inputName"
              outlined
              name="name"
              label="Full name"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              v-model="inputAddress"
              outlined
              name="address"
              label="Property address"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              v-model="inputAmount"
              outlined
              name="amount"
              label="Amount"
              prefix="£"
              :rules="rules"
            ></v-text-field>            
            <v-text-field
              :value="orderID"
              readonly
              filled
              shaped
              name="reference"
              label="Reference"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Confirmed details -->

        <div v-if="formConfirmed" class="">
          <div>
            <p class="mb-0 font-weight-light text-body-1">Name:</p>
            <p class="my-0 text-h6">
              {{ inputName }}
            </p>

            <p class="mb-0 mt-3 font-weight-light text-body-1">Property:</p>
            <p class="my-0 text-h6">
              {{ inputAddress }}
            </p>


            <p class="mb-0 mt-3 font-weight-light text-body-1">Amount:</p>
            <p class="my-0 text-h6">
              £{{ inputAmount }}
            </p>

            <p class="mb-0 mt-3 font-weight-light text-body-1">Order ID:</p>
            <p class="my-0 text-h6">
              {{ orderID }}
            </p>
          </div>
          <p class="mt-3 mb-0 text-caption">Please note that payments will be collected by Letting Management Centre Ltd (AgentOS)</p>
        </div>
      </v-card-text>

      <v-card-actions class="px-4">
        <div v-if="formConfirmed">
          <form ref="form" method="post" action="https://mdepayments.epdq.co.uk/ncol/test/orderstandard_utf8.asp" id=form1 name=form1>

            <!-- general parameters: see Form parameters -->

            <input type="hidden" name="PSPID" :value="pspID">

            <input type="hidden" name="ORDERID" :value="orderID">

            <input type="hidden" name="AMOUNT" :value="formattedAmount">

            <input type="hidden" name="CURRENCY" :value="currency">

            <input type="hidden" name="LANGUAGE" :value="language">

            <input type="hidden" name="CN" value="">

            <input type="hidden" name="EMAIL" value="">

            <input type="hidden" name="OWNERZIP" value="">

            <input type="hidden" name="OWNERADDRESS" value="">

            <input type="hidden" name="OWNERCTY" value="">

            <input type="hidden" name="OWNERTOWN" value="">

            <input type="hidden" name="OWNERTELNO" value="">



            <!-- check before the payment: see Security: Check before the payment -->

            <input type="hidden" name="SHASIGN" :value="shaSignIn">

            <!-- layout information: see Look and feel of the payment page -->

            <input type="hidden" name="TITLE" value="">

            <input type="hidden" name="BGCOLOR" value="">

            <input type="hidden" name="TXTCOLOR" value="">

            <input type="hidden" name="TBLBGCOLOR" value="">

            <input type="hidden" name="TBLTXTCOLOR" value="">

            <input type="hidden" name="BUTTONBGCOLOR" value="">

            <input type="hidden" name="BUTTONTXTCOLOR" value="">

            <input type="hidden" name="LOGO" value="">

            <input type="hidden" name="FONTTYPE" value="">



            <!-- post payment redirection: see Transaction feedback to the customer -->

            <input type="hidden" name="ACCEPTURL" :value="acceptURL">

            <input type="hidden" name="DECLINEURL" :value="errorURL">

            <input type="hidden" name="EXCEPTIONURL" value="">

            <input type="hidden" name="CANCELURL" :value="paymentsURL">

            <input type="submit" value="checkout" id=submit2 name=submit2>

          </form>
        </div>
        <v-btn 
          v-if="formConfirmed"
          @click="formConfirmed = false"
          class="ml-5"
          color="red" 
          outlined
        >back</v-btn>
        <v-btn 
          @click="confirmDetails"
          v-if="!formConfirmed"
          :disabled="!inputAmount || !inputAddress || !inputName"
          color="agentSecondary"
          depressed
          dark
        >
          <span class="">Continue</span>
          <v-icon color="white" right>mdi-arrow-right</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <div>
          <v-img
            alt="AgentOS logo"
            contain
            src="../../assets/AgentOS-Logo.png"
            width="120"
          />
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import sha512 from 'js-sha512'
// import { API } from 'aws-amplify'

export default {
  name: 'paymentsForm',
  data() {
    return {
      inputName: 'Glyn Trott',
      inputAddress: '10 Downing Street',
      inputAmount: '350',
      encodedText: '',
      rules: [
        value => !!value || `Value required`
      ],
      // Hidden params 
      passPhrase: 'drFUhy6f}F*E6[zw1ED',
      shaSignIn: '',
      pspID: 'epdq7019635',
      orderID: '',
      currency: 'GBP',
      language: 'en_US',
      //reroute URL params
      paymentsURL: 'http://localhost:8080/payments',
      acceptURL: 'http://localhost:8080/payments/success',
      errorURL: 'http://localhost:8080/payments/error',
      //other
      formConfirmed: false
    }
  },
  created() {
    this.createRandomOrderID()
  },
  computed: {
    formattedAmount() {
      if(this.inputAmount) {
        const amountToNumber = parseFloat(this.inputAmount.replace(/,/g, ''))
        return amountToNumber * 100
      }
      return 0
    }
  },
  methods: {
    encodeText() { // TEST
      this.encodedText = sha512(this.inputName).toUpperCase()
    },
    confirmDetails() {

      const stringToHash = `ACCEPTURL=${this.acceptURL}${this.passPhrase}AMOUNT=${this.formattedAmount}${this.passPhrase}CANCELURL=${this.paymentsURL}${this.passPhrase}CURRENCY=${this.currency}${this.passPhrase}DECLINEURL=${this.errorURL}${this.passPhrase}LANGUAGE=${this.language}${this.passPhrase}ORDERID=${this.orderID}${this.passPhrase}PSPID=${this.pspID}${this.passPhrase}` 
      console.log(stringToHash)

      const hashedString = sha512(stringToHash)
      const promise = Promise.resolve(hashedString)
      promise.then( () => {
        console.log(hashedString)
        this.shaSignIn = hashedString
        this.formConfirmed = true
      })

      // this.$emit('closeDialog')
      
      // this.resetFormValues()



      // Lambda function code ==================================
      // ======================================================

      // const postParams = {
      //   body: {
      //     string: 'Test string'
      //   },
      // }
      // API.post('agentSignMainRest', '/hash', postParams)
      //   .then( res => {
      //       console.log('/hash response: ', res)
      //   })


      /****************************
      * SHA512 hashing method *
      ****************************/

      // app.post('/hash', function(req, res) {
      //   // const query = req.query.query

      //   const hashedString = sha512('Hash this string')
        
      //   res.json({
      //     data: hashedString,
      //     query: req.query,
      //     status: '200',
      //   })
      
      // });
    },
    createRandomOrderID() {
      // program to generate random strings

      const randomString = Math.random().toString(36).substring(2,7);
      this.orderID = randomString
    },
    closeDialog() {
      this.$emit('closeDialog')
      this.formConfirmed = false
    },
    resetFormValues() {
      this.inputName = ''
      this.inputAddress = ''
      this.inputAmount = ''
      this.shaSignIn = ''
    }
  },
  watch: {
    inputAmount(newValue) {
      if(newValue.length > 3) { // added .length to solve error caused by setting rent on created()
        const result = newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.$nextTick(() => this.inputAmount = result);
      }
    }
  }
}
</script>

<style scoped>
#submit2 {
  background:#59ddb7;
  color: #262637;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 3px;
  height: 36px;
  width: 150px;
  padding: 5px 8px;

}
</style>